import $ from "jquery";
import "popper.js"; // Bootstrap requires this
import "bootstrap";
//import "fslightbox"; 
import "slick-carousel";

// Can import modules as needed
// ex. import 'bootstrap/js/dist/dropdown';
// import "./scss/style.scss"; // This is now being imported in the webpack.config file 

// This is for slick carousels
global.jQuery = global.$ = require('jquery');

// # ---------------------------------------------------------------
// # Custom Methods...
(function ($) {

	// Utility function to check if element is visible
	function isVisible($el) {
		var winTop = $(window).scrollTop();
		var winBottom = winTop + $(window).height();
		var elTop = $el.offset().top;
		var elBottom = elTop + $el.height();
		return ((elBottom <= winBottom) && (elTop >= winTop));
	}

	// Header scrolled
	// Check scroll amount, show / hide header items accordingly
	// function checkScrolled() {

	// 	let $elem = $("header#site-header");

	// 	if ($elem.length) {
	// 		let $window = $(window);
	// 		let docViewTop = $window.scrollTop();
	// 		let docViewBottom = docViewTop + $window.height();
	// 		let elemTop = $elem.offset().top;
	// 		let elemBottom = elemTop + $elem.height();

	// 		if (elemBottom <= docViewBottom && elemTop >= docViewTop) {
	// 			// $(".lg-hide-on-scrolled").each(function () {
	// 			//   $(this).fadeIn(250);
	// 			// });
	// 			// $(".lg-show-on-scrolled").each(function () {
	// 			//   $(this).fadeOut(1);
	// 			// });
	// 			$("nav.fixed-top").removeClass("navbar-scrolled");
	// 			//$(".custom-logo-link").removeClass("scrolled");
	// 		} else {
	// 			// $(".lg-hide-on-scrolled").each(function () {
	// 			//   $(this).fadeOut(1);
	// 			// });
	// 			// $(".lg-show-on-scrolled").each(function () {
	// 			//   //$(this).fadeIn(250);
	// 			//   $(this).removeClass("d-none");
	// 			//   $(this).fadeIn(1);
	// 			// });
	// 			$("nav.fixed-top").addClass("navbar-scrolled");
	// 			//$(".custom-logo-link").addClass("scrolled");
	// 		}
	// 	}
	// }

	// Shrink header on scroll OR page load
	// $(window).scroll(function () {
	// 	checkScrolled();
	// });

	// $(document).ready(function () {
	// 	checkScrolled();
	// });

	// Slick sliders
	$(function () {
		$('.logo-slider-wrap').slick({
			slidesToShow: 7,
			slidesToScroll: 1,
			infinite: true,
			arrows: true,
			autoplay: true,
			prevArrow:
				'<a class="slick-prev"><i class="bi bi-chevron-left"></i></a>',
			nextArrow:
				'<a class="slick-next"><i class="bi bi-chevron-right"></i></i></a>',

			responsive: [
				{
					breakpoint: 768,
					settings: {
						slidesToShow: 5,
					}
				},
				{
					breakpoint: 600,
					settings: {
						slidesToShow: 3,
					}
				},
			],
		});
	});

	// Set carousel height to the tallest slide to 
	// prevent content shifting
	// function setCarouselHeights() {
	// 	$('.carousel-inner').each(function () {
	// 		var heights = [];
	// 		$(this).children('.carousel-item').each(function () {
	// 			heights.push($(this).height());
	// 		});

	// 		$(this).children('.carousel-item').height(Math.max(...heights));
	// 	});
	// }
	// setCarouselHeights();


	// Add class to header when mobile nav is open
	$('.navbar-toggler').click(function () {
		//$('header#site-header').toggleClass('nav-open');
	});

	// Toggle class on top level menu items when they are clicked
	$('li.menu-item').click(function () {
		$('li.menu-item').not(this).each(function () {
			$(this).removeClass('active');
		});
		$(this).toggleClass('active');
	});

	// Toggle class on #header-nav when menu item is opened or closed
	// this is to hide/show the other menu items on mobile
	$('li.menu-item-has-children').click(function () {
		$('#header-nav').toggleClass('nav-item-open');
	});


	// $(window).scroll(function () {

	// });

	// Animated counter block
	function animateCounter($counter) {
		$counter.prop('Counter', $counter.data('startnum')).animate({
			Counter: $counter.data('endnum')
		}, {
			duration: $counter.data('duration'),
			easing: 'swing',
			step: function (now) {
				// Does not have decimal or a comma
				$counter.children('.text').text(Number(this.Counter).toFixed(0).toLocaleString('en'));
			}
		});
		$counter.addClass('animation-done');
	}

	// When window scrolls, check if any animated items are visible
	$(window).scroll(function () {
		$('.animated-counter').each(function () {
			if (isVisible($(this))) {
				if (!$(this).hasClass('animation-done'))
					animateCounter($(this));
			}
		});
	});
	// END Animated Counter

	// Filter Programs Page
	$('#program-filter-menu  button.filter').click(function () {
		// console.log($(this).data('filter'));		
		$('#program-filter-menu  button.filter').each(function () {
			$(this).removeClass('active');
		});
		$(this).addClass('active');

		if ($(this).data('filter-type') == 'day') {

			$('.program-wrap').each(function () {
				$(this).show();
			});

			$('.programs-day-section').each(function () {
				$(this).hide();
			});
			$('.programs-day-section#' + $(this).data('filter')).each(function () {
				$(this).show();
			});
			$('#filter-toggle-btn').text($(this).data('filter-label'));
		}

		if ($(this).data('filter-type') == 'grade') {

			$('.programs-day-section').each(function () {
				$(this).show();
			});

			$('.program-wrap').each(function () {
				$(this).hide();
			});
			$('.program-wrap.' + $(this).data('filter')).each(function () {
				$(this).show();
			});
			$('#filter-toggle-btn').text('Grade: ' + $(this).data('filter-label'));
		}

		$('#clearfilters').removeClass('disabled');
	});

	$('#clearfilters').click(function () {
		$(this).addClass('disabled');
		$('#filter-toggle-btn').text('SHOW ALL');
		$('#program-filter-menu  button.filter').each(function () {
			$(this).removeClass('active');
		});
		$('.programs-day-section').each(function () {
			$(this).show();
		});
		$('.program-wrap').each(function () {
			$(this).show();
		});

	});

})($);


$('#mobile-nav li.menu-item-has-children > a').after($('<div class="mobile-sub-menu-toggle"><i class="fa fa-plus"></i><i class="fa fa-minus"></i></div>'));

// $('.mobile-menu-lower #header-secondary-navigation li.menu-item-has-children > a').after($('<div class="mobile-sub-menu-toggle"><i class="fa fa-plus"></i><i class="fa fa-minus"></i></div>'));
$('.mobile-sub-menu-toggle').click(function (e) {
	e.preventDefault();
	$('.mobile-sub-menu-toggle').not($(this)).each(function () {
		$(this).removeClass('open');
		return $(this).siblings('ul.sub-menu').slideUp('fast');
	});
	$(this).siblings('ul.sub-menu').slideToggle('fast');
	return $(this).toggleClass('open');
});

